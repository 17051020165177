/* eslint-disable jsx-a11y/control-has-associated-label */
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Option } from './CountryPrefixSelector';
import { ClearIcon, CloseIcon, FlagPlaceholder, SearchIcon } from './Icons';
import * as Flags from './data/flags';
import { FlagsType } from './data/flagsType';
import { useClickOutside } from '../../helpers/layout/useClickOutside';

interface WebsiteMenuMobileOverlayProps {
  isOpen: boolean;
  closeModal: () => void;
  options: Array<Option>;
  label: string;
  onChange: (value: Option) => void;
  searchFieldPlaceholder: string;
}

const PrefixesMobileOverlay = ({
  isOpen,
  closeModal,
  options,
  label,
  onChange,
  searchFieldPlaceholder,
}: WebsiteMenuMobileOverlayProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  const searchOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectOptions(
      options.filter((option: Option) => {
        return event.target.value
          ? !!`${option.countryName} ${option.prefix}`
              .toLocaleLowerCase()
              .includes(event.target.value.toLocaleLowerCase())
          : option;
      }),
    );
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSelectOptions(options);
  };

  const renderOption = (option: Option) => {
    const Flag = (Flags as FlagsType)[option.id];
    return (
      <div
        key={option.id}
        onClick={() => {
          onChange(option);
          clearSearch();
        }}
        className="flex items-center cursor-pointer gap-2 py-2 px-4 sm:py-4 hover:bg-GSblue/50"
      >
        {Flag ? <Flag /> : <FlagPlaceholder />}
        <div className="text-GSdeep/900 text-base sm:text-lg font-normal">
          {option.countryName}
        </div>
        <div className="italic text-GSdeep/300 text-base leading-5 font-normal">
          {option.prefix}
        </div>
      </div>
    );
  };

  const optionsSelectRef = useClickOutside(() => {
    closeModal();
  });

  const anyPreferredOptions = selectOptions?.some(
    (option) => option.isPreferred,
  );

  return (
    <nav
      className={cx(
        'fixed right-0 top-0 h-full w-full z-[100] lg:hidden bg-black bg-opacity-25',
        {
          block: isOpen,
          hidden: !isOpen,
        },
      )}
    >
      <div
        className="ml-auto flex flex-col h-full w-full sm:max-w-md bg-white"
        ref={optionsSelectRef}
      >
        <div className="flex justify-between items-center py-2 px-4 sm:p-5 sm:pl-6 border-b border-GSparchment/200">
          <span className="text-base sm:text-lg font-bold text-GSdeep/500">
            {label}
          </span>
          <button
            type="button"
            className="select-none border-none focus:outline-none"
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="flex flex-row items-center border-b border-GSsteel/300 px-4 pt-4 pb-2 gap-2 sm:6 text-base sm:text-lg">
          <div className="cursor-pointer">
            <SearchIcon />
          </div>
          <input
            type="text"
            onChange={searchOptions}
            value={searchTerm}
            placeholder={searchFieldPlaceholder}
            className="flex-1 text-GSdeep/900 placeholder:text-GSsteel/500 focus:outline-none h-full bg-transparent"
          />
          {searchTerm.length ? (
            <div className="cursor-pointer" onClick={clearSearch}>
              <ClearIcon />
            </div>
          ) : null}
        </div>
        <div
          className={cx('flex flex-col overflow-y-auto gap-1 sm:gap-0', {
            'py-4': !anyPreferredOptions,
          })}
        >
          {anyPreferredOptions && (
            <div className="flex flex-col gap-4 sm:gap-0 bg-GSblue/50 py-4 border-y border-GSblue/100">
              {selectOptions
                ?.filter((option) => option.isPreferred)
                .map((option: Option) => renderOption(option))}
            </div>
          )}
          <div className="flex flex-col gap-1 sm:gap-0">
            {selectOptions
              ?.filter((option) => !option.isPreferred)
              .map((option: Option) => renderOption(option))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PrefixesMobileOverlay;
